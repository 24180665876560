<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">老推新返利</span>
    </div>
    <div class="content">

      <el-tabs v-model="activeTab">
        <el-tab-pane label="推荐新用户"
                     name="users">
          <div class='table-wrapper'>
            <el-table class="export-table"
                      v-loading='loading'
                      :data='list'
                      row-key='UserId'
                      stripe
                      height="calc(100vh - 400px)"
                      table-layout='fixed'>
              <el-table-column prop='UserId'
                               label='用户ID' />
              <el-table-column prop='PhoneNumber'
                               label='手机号' />
              <el-table-column prop='StartTime'
                               label='开始返利时间'
                               :formatter='tableUnitTimeFormatter' />
              <el-table-column prop='EndTime'
                               label='结束返利时间'
                               :formatter='tableUnitTimeFormatter' />
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="推荐返利"
                     name="bonus">
          <div class='table-wrapper'>
            <el-table class="export-table-2"
                      v-loading='loading'
                      :data='list2'
                      row-key='orderId'
                      stripe
                      height="calc(100vh - 400px)"
                      table-layout='fixed'>
              <el-table-column prop='UserId'
                               label='用户ID' />
              <el-table-column prop='OrderId'
                               label='订单编号' />
              <el-table-column prop='OrderType'
                               label='订单类型' />
              <el-table-column prop='ResourceType'
                               label='资源类型'
                               :formatter="resourceTypeFormat" />
              <el-table-column prop='Total'
                               label='订单金额(元)'
                               :formatter="fentoyuan" />
              <el-table-column prop='CashPart'
                               label='现金金额(元)'
                               :formatter="fentoyuan" />
              <el-table-column prop='BonusPart'
                               label='赠金金额(元)'
                               :formatter="fentoyuan" />
              <el-table-column prop='Rebate'
                               label='返利金额(元)'
                               :formatter="fentoyuan" />
              <el-table-column prop='StartTime'
                               label='开始时间'
                               :formatter='tableUnitTimeFormatter' />
              <el-table-column prop='EndTime'
                               label='过期时间'
                               :formatter='tableUnitTimeFormatter' />
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>


export default {
  name: "OldWithNew",
  data () {
    return {
      loading: false,
     
      list: [],
      list2: [],
      
      activeTab: "users",
      hasData: false,
    }
  },
  mounted () {
    this.$store.dispatch("user/oldwithnewdata").then((res) => {
      if (res.RetCode == 0) {
        this.list = res.Users;
        this.list2 = res.Rebates;
      }
    });
  },
  methods: {
    tableUnitTimeFormatter: (row, column, cellValue, index) => {
      if (cellValue == 0) {
        return '-'
      }
      return new Date(cellValue * 1000).toLocaleDateString()
    },
    fentoyuan: (row, column, cellValue, index) => {
      return (cellValue / 100).toFixed(2)
    },
    resourceTypeFormat: (row, column, cellValue, index) => {
      if (cellValue == "PHONE") {
        return "云手机"
      } else if (cellValue == "PATH") {
        return "直播快"
      }
      return "-"
    },
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>